/* eslint-disable no-undef */
class Advisor extends HTMLElement {
  constructor() {
    super();

    this.innerHTML = `
        <div class="card" data-aos="fade-down" style="width: 18rem; margin: 30px auto;">
          <img tabindex="0" data-src="../images/fery-large.webp" class="card-img-top lazyload" alt="muhammad fery syahputra" style="width: 150px height: 150px" crossorigin="anonymous" >
          <div class="card-body text-center" >
            <h5  tabindex="0" class="card-title text-uppercase fw-bold">Muhammad fery syahputra</h5>
            <p tabindex="0" class="card-text text-capitalize">full-stack developer</p>
            <div class="d-flex justify-content-center " >
                <a  tabindex="0" href="https://github.com/MuhammadFerySyahputra" target="_blank" class="fs-3 mr-3 ijonih"><i class="bi bi-github"></i></a>
                <a  tabindex="0" href="https://www.linkedin.com/in/muhammadferysyahputra/" target="_blank" class="fs-3 mr-3 ijonih"><i class="bi bi-linkedin"></i></a>
                <a  tabindex="0" href="https://www.instagram.com/ferysyahputra456" target="_blank" class="fs-3 mr-3 ijonih"><i class="bi bi-instagram"></i></a>
                <a  tabindex="0" href="https://t.me/Ferys2343" target="_blank" class="fs-3 mr-3 ijonih"><i class="bi bi-telegram"></i></a>
            </div>
          </div>
        </div>
      `;
  }
}

customElements.define('team-advisor', Advisor);
