/* eslint-disable */
import './components/main';
import '../styles/style.css';
import '../styles/responsive.css';
// import App from './views/app';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// const app = new App({
//   button: document.querySelector('#hamburgerButton'),
//   drawer: document.querySelector('#navigationDrawer'),
//   content: document.querySelector('#mainContent'),
// });

// window.addEventListener('hashchange', () => {
//   app.renderPage();
// });

// window.addEventListener('load', () => {
//   app.renderPage();
// });

// Memuat App secara dinamis
let app;

function loadApp() {
  // Pastikan app hanya dimuat satu kali
  if (app) return;

  import('./views/app')
    .then(module => {
      app = new module.default({
        button: document.querySelector('#hamburgerButton'),
        drawer: document.querySelector('#navigationDrawer'),
        content: document.querySelector('#mainContent'),
      });

      window.addEventListener('hashchange', () => {
        app.renderPage();
      });

      window.addEventListener('load', () => {
        app.renderPage();
      });
    })
    .catch(error => {
      console.error('Error loading the App module:', error);
    });
}

// Panggil fungsi untuk memuat App saat diperlukan
document.addEventListener('DOMContentLoaded', loadApp);
